import styled, { css } from "styled-components";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import { ImagesWithCaptionsItemProps } from "./types";

export const List = styled(FlexBox)`
  @media ${media.mobile} {
    position: relative;
    width: 100%;
    min-height: 30.5rem;
  }

  @media ${media.tablet} {
    min-height: 26.25rem;
  }
`;

export const ListItem = styled.li<ImagesWithCaptionsItemProps>`
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @media ${media.mobile} {
    position: absolute;
  }

  &:first-child {
    @media ${media.mobile} {
      bottom: 0;
      left: 0;
    }
  }

  &:nth-child(2) {
    @media ${media.mobile} {
      top: 0;
      right: 9rem;
    }

    @media ${media.tablet} {
      top: 3rem;
      left: 20.5rem;
      right: initial;
    }
  }

  &:nth-child(3) {
    @media ${media.mobile} {
      bottom: 8.5rem;
      right: 6.25rem;
    }

    @media ${media.tablet} {
      bottom: 1.5rem;
      right: 10.85rem;
    }
  }

  ${({ isWithoutLastTitle }) =>
    isWithoutLastTitle &&
    css`
      &:first-child {
        @media ${media.desktop} {
          left: initial;
          right: 23.75rem;
        }
      }

      &:nth-child(2) {
        @media ${media.mobile} {
          right: 11.75rem;
        }

        @media ${media.tablet} {
          left: initial;
          right: 3rem;
        }
      }

      &:nth-child(3) {
        @media ${media.mobile} {
          right: 6.25rem;
        }

        @media ${media.tablet} {
          left: initial;
          right: 0;
        }
      }
    `}
`;
