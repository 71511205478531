import React from "react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import Image from "@components/atoms/Image";

import * as Styled from "./styles";
import { ImageWithCaptionProps } from "./types";

const ImageWithCaption: React.FC<ImageWithCaptionProps> = ({
  index,
  title,
  image
}) =>
  image ? (
    <Styled.Figure {...{ index }} aria-label="Image with caption">
      <Image
        {...image}
        style={{
          objectFit: "cover"
        }}
        fill
      />
      {title && (
        <Styled.Caption>
          <Typography variant="body4">{title}</Typography>
        </Styled.Caption>
      )}
    </Styled.Figure>
  ) : null;

export default ImageWithCaption;
