import React from "react";
import { storyblokEditable } from "@storyblok/react";

import { getImage } from "@utils/getImage";
import ImagesWithCaptions from "@components/molecules/ImagesWithCaptions";

import { ImagesWithCaptionsStoryblokProps } from "./types";

const ImagesWithCaptionsStoryblok: React.FC<
  ImagesWithCaptionsStoryblokProps
> = ({ blok }) => {
  const { items } = blok;

  const imagesWithCaptionsItems = items.map(
    ({ _uid, title, image }) => ({
      key: _uid,
      title,
      image: getImage(image)
    })
  );

  return (
    <ImagesWithCaptions
      items={imagesWithCaptionsItems}
      {...storyblokEditable(blok)}
    />
  );
};

export default ImagesWithCaptionsStoryblok;
