import styled, { css } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import { StyledFigureProps } from "./types";

export const Caption = styled.figcaption`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0.625rem;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 13.75rem;
`;

export const Figure = styled.figure<StyledFigureProps>`
  position: relative;
  aspect-ratio: 300 / 224;

  @media ${media.mobile} {
    height: 14rem;
    width: 18.75rem;

    ${({ index }) =>
      index === 0 &&
      css`
        ${Caption} {
          bottom: 0;
          left: 0;
        }
      `}

    ${({ index }) =>
      index === 1 &&
      css`
        ${Caption} {
          bottom: initial;
          left: initial;
          top: 0;
          right: 0;
        }
      `}
    
      ${({ index }) =>
      index === 2 &&
      css`
        width: 11.875rem;
        height: 8.875rem;

        ${Caption} {
          bottom: 100%;
          left: 100%;
          transform: translate(-3rem, 3rem);
          width: 11.25rem;
        }
      `}
  }
`;
