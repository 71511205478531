import React from "react";

import * as Styled from "./styles";
import ImageWithCaption from "./partials/ImageWithCaption";
import { ImagesWithCaptionsProps } from "./types";

const ImagesWithCaptions: React.FC<ImagesWithCaptionsProps> = ({
  items
}) => {
  const isWithoutLastTitle = !items.at(-1).title;

  return items.length ? (
    <Styled.List
      as="ul"
      flexDirection="column"
      aria-label="Images with captions"
    >
      {items.map(({ key, title, image }, index) => (
        <Styled.ListItem {...{ key, isWithoutLastTitle }}>
          <ImageWithCaption {...{ index, title, image }} />
        </Styled.ListItem>
      ))}
    </Styled.List>
  ) : null;
};

export default ImagesWithCaptions;
